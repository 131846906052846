export enum AppUrlIndex {
    Long = 0,
    Short = 1,
}

export class GlobalAppUrls {
    public Client = {
        Account: {
            ForgotPassword: "/account/forgotpassword",
            ForgotPasswordSuccess: "/account/forgotpassword-success",
            Login: "/account/login",
            Logout: "/logout",
            Register: "/account/register",
            SessionExpired: "/sessionexpired",
        },
        Admin: {
            AdminHome: "/admin/dashboard/userslist",
            AdminAddEditUser: "/admin/dashboard/userslist/user/:usedId",
            BDMHome: "/admin/dashboard/bdmslist",
            BDMAddEdit: "/admin/dashboard/bdmslist/bdm/:bdmId",
            ComplaintAssigneeHome: "/admin/dashboard/calist",
            ComplaintAssigneeAddEdit: "/admin/dashboard/calist/ca/:caId",
            LogViewer: "/admin/logviewer",
            TSMHome: "/admin/dashboard/tsmslist",
            TSMAddEdit: "/admin/dashboard/tsmslist/tsm/:tsmId",
        },
        Aftersales: {
            Default: ["/aftersales/*", "/*"],
            Complaints: ["/aftersales/complaints", "complaints/*"],
            ComplaintsView: ["/aftersales/complaints/view/:complaintid/details", "view/:complaintid"],
            AddEdit: ["/aftersales/addedit/:complainttype/:projectid/:complaintid", "addedit/:complainttype/:projectid/:complaintid"],
        },
        ConditionReport: {
            Default: ["/conditionreport/*", "/*"],
            AddEdit: ["/conditionreport/addedit/:reportId", "addedit/:reportId"],
            AddPdf: ["/conditionreport/addpdf/:reportId/:templateId", "addpdf/:reportId/:templateId"],
            EditPdf: ["/conditionreport/editpdf/:reportId/:pdfreportId/", "editpdf/:reportId/:pdfreportId/"],
            ViewRaw: ["/conditionreport/view/:reportId", "view/:reportId"],
            List: ["/conditionreport/list", "list/"],
            PdfList: ["/conditionreport/pdflist/:reportId", "pdflist/:reportId"],

            Setup: ["/conditionreport/setup", "setup/"],
            AddEditTemplate: ["/conditionreport/setup/addedit/:templateId", "setup/addedit/:templateId"],
        },
        ConfirmPassword: "/confirmpassword/:token",
        Dashboard: { Default: ["/dashboard/*", "/*"], Tasks: ["/dashboard/tasks", "tasks"] },
        Directory: {
            Customers: ["/directory/customers", "customers"],
            CustomersNew: "/directory/customers/new",
            CustomersEdit: "/directory/customers/edit/:customerId",
            CustomersView: ["/directory/customers/view/:customerId", "view/:customerId"],
            CustomersViewEdit: ["/directory/customers/viewedit/:customerId", "viewedit/:customerId"],
            Contacts: ["/directory/contacts", "contacts"],
            ContactCustomerView: ["/directory/contacts/customerview/:customerId", "customerview/:customerId"],
            Clients: ["/directory/clients", "clients"],
            Default: ["/directory/*", "/*"],
            Properties: ["/directory/properties", "properties"],
            PropertyNew: "/directory/properties/new",
            PropertyView: "/directory/properties/view/",
        },
        Error: "/error",
        Events: {
            Default: ["/events/*", "/*"],
            List: ["/events/list", "list/"],
        },
        ForgotPassword: "/api/forgotpassword",
        Home: "/",
        ResetPassword: "/resetpassword/:token",
        //  Tasks: "/tasks",
        Theme: "/theme",
        TSM: { Default: ["/tsm/*", "/*"], Tsm: ["/tsm/list", "tsm"] },
        Reports: { Default: ["/reports/*", "/*"], Reports: ["/reports/list", "reports"] },
        Work: {
            Default: ["/work/*", "/*"],
            List: ["/work/list", "/list"],
            Multi: {
                Default: ["/work/multi", "multi"],
                Edit: ["/work/multi/edit/:multisiteprojectid", "edit/:multisiteprojectid"],
                New: ["/work/multi/new", "new"],
                View: ["/work/multi/view/:multisiteprojectid/details", "view/:multisiteprojectid"],
            },
            MultiTest: ["/work/multi", "multi"],
            Master: {
                Default: ["/work/master", "master"],
                Edit: ["/work/master/edit/:masterprojectid", "edit/:masterprojectid"],
                View: ["/work/master/view/:masterprojectid/details", "view/:masterprojectid"],
            },
            MasterNew: "/work/master/new",
            Quotes: {
                CustomersView: ["/work/quotes/customerview/:projectquoteid/:customerId", "customerview/:projectquoteid/:customerId"],
                CustomersViewEdit: ["/work/quotes/customerviewedit/:projectquoteid/:customerId", "customerviewedit/:projectquoteid/:customerId"],
                Default: ["/work/quotes", "quotes"],
                Edit: ["/work/quotes/edit/:projectquoteid", "edit/:projectquoteid"],
                View: ["/work/quotes/view/:projectquoteid", "view/:projectquoteid"],
                New: ["/work/quotes/new/:masterprojectid", "new/:masterprojectid"],
            },
            Tasks: {
                AddEdit: [
                    "/work/master/addedit/task/:tasktype/:masterprojectid/:taskid",
                    "/directory/customers/addedit/task/:tasktype/:customerId/:taskid",
                    "/aftersales/addedit/task/:tasktype/:complaintid/:taskid",
                    "/admin/dashboard/:tasktype/addedit/:taskid",
                ],
            },
        },
    };

    public Server = {
        Account: {
            Login: "/api/account/login",
            Logout: "/api/account/logout",
            ResetPassword: {
                Reset: "/api/account/resetpassword",
                VerifyToken: "/api/account/resetpasswordtoken",
            },
            ForgotPassword: "/api/account/forgotpassword",
            SetPassword: "/api/account/setpassword",
            Register: "/api/account/register",
            ResendEmailConfirmation: "api/account/resendemailconfirmation",
            CreateNewPassword: "/api/account/createnewpassword",
            User: {
                DeleteUser: "/api/user/delete",
                DeleteRole: "/api/role/delete",
            },
        },
        Admin: {
            User: {
                GetAllUsersForList: "/api/user/getusersforadminlist",
                GetAllUsersForListAndRelated: "/api/user/getusersforadminlistandrelated",
                IsEmailInUse: "/api/User/IsEmailInUse",
                Register: "/api/account/register",
                ResetFailedLoginAttemptsCount: "/api/user/ResetLoginAttempts",
                Upsert: "/api/user/upsert",
            },
            Roles: {
                GetRoles: "/api/roles/getall",
            },
            TSM: {
                GetAllTSMsForListAndRelated: "/api/tsm/gettsmsforadminlistandrelated",
                GetUsersForDropdown: "/api/tsm/getusersfordropdown",
                Upsert: "/api/tsm/upsert",
            },
            BDM: {
                GetAllBDMsForListAndRelated: "/api/bdm/getbdmsforadminlistandrelated",
                GetUsersForDropdown: "/api/bdm/getusersfordropdown",
                Upsert: "/api/bdm/upsert",
            },
            ComplaintAssinee: {
                GetAllCAsForListAndRelated: "/api/ca/getcasforadminlistAndRelated",
                GetUsersForDropdown: "/api/ca/getusersfordropdown",
                Upsert: "/api/ca/upsert",
            },
        },
        AfterSales: {
            Complaints: {
                CorrectiveActionUpsert: "/api/complaints/correctiveactionupsert",
                DeleteCorrectiveAction: "/api/complaints/deletecorrectiveactionbyid",
                DeletePreventativeAction: "/api/complaints/deletepreventativeactionbyid",
                DownloadComplaintWithCategoryCSV: "/api/complaints/downloadcomplaintWithcategorycsv",
                DownloadComplaintsBetweenDatesPDF: "/api/complaints/downloadcomplaintbetweendatespdf",

                DownloadProjectQuoteComplaintPDF: "/api/complaints/downloadprojectquotecomplaintpdf",
                DownloadMasterProjectComplaintPDF: "/api/complaints/downloadmasterprojectcomplaintpdf",

                GetCorrectiveActionsForView: "/api/complaints/getcorrectiveactionsforview",
                GetPreventativeActionsForView: "/api/complaints/getpreventativeactionsforview",
                GetDetailsForView: "/api/complaints/getdetailsforview",
                GetForList: "/api/complaints/getcomplaintlist",
                GetRelatedForMasterProject: "/api/complaints/getrelatedformasterproject",
                GetRelatedForProjectQuote: "/api/complaints/getrelatedforprojectquote",

                MasterProjectComplaintGet: "/api/complaints/masterprojectcomplaintget",
                MasterProjectComplaintUpsert: "/api/complaints/masterprojectcomplaintupsert",
                PreventativeActionUpsert: "/api/complaints/preventativeactionupsert",
                ProjectQuoteComplaintGet: "/api/complaints/projectquotecomplaintget",
                ProjectQuoteComplaintUpsert: "/api/complaints/projectquotecomplaintupsert",

                MasterProjectComplaintDocumentDeleteById: "/api/masterprojectcomplaintdocument/deletedocumentbyid",
                MasterProjectComplaintDocumentsGetByComplaintId: "/api/masterprojectcomplaintdocument/documentsgetbycomplaintid",
                MasterProjectComplaintDocumentUpsert: "/api/masterprojectcomplaintdocument/uploaddocument",

                ProjectQuoteComplaintDocumentDeleteById: "/api/projectquotecomplaintdocument/deletedocumentbyid",
                ProjectQuoteComplaintDocumentsGetByComplaintId: "/api/projectquotecomplaintdocument/documentsgetbycomplaintid",
                ProjectQuoteComplaintDocumentUpsert: "/api/projectquotecomplaintdocument/uploaddocument",
            },
        },
        ConditionReport: {
            GetGetConditionReportForViewById: "/api/conditionreport/getconditionreportforviewbyid/{:conditionReportId}",
            GetAllForList: "/api/conditionreport/getallconditionreportsforlist",
            GetListFilters: "/api/conditionreport/getlistfilters",
            UploadJsonFile: "/api/conditionreport/uploadjsonfile",
            GetEligibleTemplatesForConditionReport: "/api/conditionreport/geteligibletemplatesforconditionpeport",
            DeleteConditionReportById: "/api/conditionreport/deleteById",
        },
        ConditionReportPdf: {
            CreateNew: "/api/conditionreportpdf/createnewpdf",
            GetPdfById: "/api/conditionreportpdf/getpdfbyid",
            GetListItemsForConditionReportid: "/api/conditionreportpdf/getlistitemsforconditionreportid",
            UpdatePdfData: "/api/conditionreportpdf/updatepdfdata",
            DownloadPdf: "/api/conditionreportpdf/downloadconditionreportpdf",
            GetPdfHtml: "/api/conditionreportpdf/getconditionreportpdfhtml",
            DeleteById: "/api/conditionreportpdf/deletePdfById",
            UpdateDocumentContactId: "/api/conditionreportpdf/updatedocumentcontactid",
            UploadImage: "api/conditionreportpdf/uploadImage",
        },
        ConditionReportTemplate: {
            GetTemplateList: "/api/conditionreporttemplate/gettemplatelist",
            GetConditionReportTemplateById: "/api/conditionreporttemplate/getConditionreportremplatebyId/{:templateId}",
            SetSectionIsDeleted: "/api/conditionreporttemplate/setsectionisdeleted",
            UploadJsonFileForTemplate: "/api/conditionreporttemplate/uploadjsonfilefortemplate",
            UpsertConditionReportTemplateSection: "/api/conditionreporttemplate/upsertconditionreporttemplatesection",
            UpsertConditionReportTemplateSections: "/api/conditionreporttemplate/upsertconditionreporttemplatesections",
            UpsertSectionOrdinals: "/api/conditionreporttemplate/updatesectionordinals",
            DeleteById: "/api/conditionreporttemplate/deletetemplatebyid",

            GetTemplateCSSFile: "/api/conditionreporttemplate/gettemplatecssforedit/{:templateId}",
            UpsertTemplateCSSFile: "/api/conditionreporttemplate/upsertconditionreportcssfile",
        },
        Contact: {
            GetContactListAndRelated: "/api/contact/getcontactlistAndRelated",
            DownloadCSV: "api/contact/downloadcsv",
            GetCustomersForDropdown: "/api/contact/getcustomersfordropdown",
            GetCustomerTypesForDropdown: "/api/contact/getcustomertypesfordropdown",
            UploadDocument: "/api/contact/uploaddocument",
        },
        Country: {
            GetCountriesForDropdown: "/api/countries/getcountriesfordropdown",
        },
        Customer: {
            DeleteContactById: "/api/customer/deletecontactbyid",
            DeleteContactDocumentById: "/api/customer/deletecontactdocumentbyid",
            DeleteCustomerDocumentById: "/api/customer/deletecustomerdocumentbyid",
            DeleteCustomerNoteDocumentById: "/api/customer/deletecustomernotedocumentbyid",
            DeleteCustomerNoteById: "/api/customer/deletecustomernotebyid",
            DownloadCSV: "api/customer/downloadcsv",

            GetContactById: "/api/customer/getcontactbyid/",
            GetContactsByCustomerId: "/api/customer/getContactsByCustomerId",

            GetCustomerDetailsForTabById: "/api/customer/getcustomerdetailsbyid",
            GetCustomerDetailsForEditById: "/api/customer/getcustomerforeditbyid",
            GetCustomerNotesByCustomerId: "/api/user/getcustomernotesbycustomerid",

            GetCustomerForCallout: "/api/customer/getcustomerforcallout",
            GetCustomerDocumentsByCustomerId: "/api/customer/getcustomerdocumentsbycustomerid",
            GetCustomerMatrix: "/api/customer/getCustomermatrixendpoint",
            GetCustomerListAndRelated: "/api/customer/getcustomerlistAndRelated",
            GetNewEditRelated: "/api/customer/getneweditrelated",
            SetCustomerStatus: "/api/customer/setcustomerstatus",
            UploadDocument: "/api/customer/uploaddocument",
            UploadNoteDocument: "/api/customer/uploadnotedocument",
            Upsert: "/api/customer/upsert",
            UpsertContact: "/api/customer/upsertcontact",
            UpsertNote: "/api/customer/upsertnote",
            GetAllTask: "/api/task/getalltask",
            GetProjectQuoteListItems: "/api/customer/getGetProjectQuoteListItems",
        },
        Document: {
            Download: "/api/document/downloaddocumentbyid",
        },
        InitialState: {
            Get: "/api/initialstate",
        },
        MasterProject: {
            DeleteCustomerById: "/api/masterprojectcustomer/deletecustomerfrommasterproject",
            DeleteMasterProjectById: "/api/masterproject/deletemasterprojectbyid",
            DeleteProjectQuoteById: "/api/masterproject/masterprojectdeleteprojectquote",
            DownloadCSV: "api/masterproject/downloadcsv",
            GetList: "/api/customer/getMasterProjectListItems",
            GetMasterProjectById: `/api/masterproject/getbyid`,
            GetMasterProjectByIdWithRelatedForInfoCallout: "/api/masterproject/getmasterprojectforcallout",
            GetMasterProjectListAndRelated: "/api/masterproject/getmasterprojectlistandrelated",
            GetPropertyDetailsById: "/api/masterproject/getpropertytabdetailsbypropertyId",
            UpdateManualStatus: "/api/masterproject/updatemanualstatus",
            UpsertMasterProjectNewTask: "/api/task/upsert",
            GetMasterProjectTasksAndRelated: "/api/task/gettaskrelatedformasterproject",
            GetComplaintsTabDetails: "/api/masterproject/getcomplaintstabdetailsbymasterproject",
            GetAllTask: "/api/task/getalltask",
            GetCustomersList: "/api/masterprojectcustomer/getcustomerlist",
            UpsertMasterProject: "/api/masterproject/upsert",
            GetNewEditRelated: "/api/masterproject/getneweditrelated",
            GetMasterProjectForDropdown: "/api/masterproject/getmasterProject",
        },
        MasterProjectDocument: {
            DeleteDocumentById: "/api/masterprojectdocument/deletedocumentbyid",
            DocumentsGetByProjectId: "/api/masterprojectdocument/documentsgetbyprojectid",
            GetDocumentTypes: "/api/masterprojectdocument/getdocumenttypes",
            UploadDocument: "/api/masterprojectdocument/uploaddocument",
        },
        MasterProjectNote: {
            DeleteNoteById: "/api/masterprojectnote/deletenotebyid",
            GetNoteAndRelatedById: "/api/masterprojectnote/getnotewithrelatedbyid",
            GetNotesByProjectId: "/api/masterprojectnote/getnotesbyprojectid",
            NoteDocumentDeleteById: "/api/masterprojectnote/notedocumentdeletebyid",
            NoteDocumentUpload: "/api/masterprojectnote/notedocumentupload",
            UpsertNote: "/api/masterprojectnote/upsertnote",
        },
        MultiSiteProject: {
            AddMasterProjectToMulti: "/api/multisiteproject/addmasterproject",
            DeleteMasterProjectFromMulti: "/api/multisiteproject/deletemasterproject",
            DeleteMasterProjectFromMultiView: "/api/multisiteproject/deletemasterprojectfromview",
            DeleteMultiSiteProjectById: "/api/multisiteproject/deletemultisiteprojectbyid",
            DownloadCSV: "api/multisiteproject/downloadcsv",
            GetFacilityManager: "/api/multisiteproject/getfacilitymanager",
            GetMultiSiteListAndRelated: "/api/multisiteproject/getlistandrelated",
            GetMultiSiteProjectById: `/api/multisiteproject/getbyid`,
            GetMultiSiteProjectByIdForEditing: `/api/multisiteproject/getbyidforediting`,
            GetMultiSiteProperties: "/api/multisiteproject/getproperties",
            GetNewEditRelated: "/api/multisiteproject/getneweditrelated",
            GetOccupiers: "/api/multisiteproject/getoccupiers",
            GetSpecifiers: "/api/multisiteproject/getspecifiers",
            UpdateManualStatus: "/api/multisite/updatemanualstatus",
            Upsert: "/api/multisiteproject/upsert",
        },
        MultiSiteProjectDocument: {
            DeleteDocumentById: "/api/multisiteprojectdocument/deletedocumentbyid",
            DocumentsGetByProjectId: "/api/multisiteprojectdocument/documentsgetbyprojectid",
            GetDocumentTypes: "/api/multisiteprojectdocument/getdocumenttypes",
            UploadDocument: "/api/multisiteprojectdocument/uploaddocument",
        },
        MultiSiteProjectNote: {
            DeleteNoteById: "/api/multisiteprojectnote/deletenotebyid",
            GetNoteAndRelatedById: "/api/multisiteprojectnote/getnotewithrelatedbyid",
            GetNotesByProjectId: "/api/multisiteprojectnote/getnotesbyprojectid",
            NoteDocumentDeleteById: "/api/multisiteprojectnote/notedocumentdeletebyid",
            NoteDocumentUpload: "/api/multisiteprojectnote/notedocumentupload",
            UpsertNote: "/api/multisiteprojectnote/upsertnote",
        },
        ProjectQuote: {
            DeleteCustomer: "/api/projectquote/deletecustomer",
            DeleteProjectQuoteById: "/api/projectquote/deleteprojectquotebyid",
            DownloadCSV: "api/projectquote/downloadcsv",
            GetByIdForEditing: "/api/projectquote/getbyidforediting",
            GetComplaintsTabDetails: "/api/projectquote/getcomplaintstabdetailsbyprojectquote",
            GetNewEditRelated: "/api/projectquote/getneweditrelated",
            GetProjectQuoteById: `/api/projectquote/getbyid`,
            GetProjectQuoteListAndRelated: "/api/projectquote/getprojectquotelistandrelated",
            InsertCustomer: "/api/projectquote/insertcustomer",
            UpdateProjectQuoteStatusById: "/api/projectquote/updateprojectquotestatusbyid",
            UpdateManualStatus: "/api/projectquote/updatemanualstatus",
            Upsert: "/api/projectquote/upsert",
        },
        ProjectQuoteDocument: {
            DeleteDocumentById: "/api/projectquotedocument/deletedocumentbyid",
            DocumentsGetByProjectId: "/api/projectquotedocument/documentsgetbyprojectid",
            GetDocumentTypes: "/api/projectquotedocument/getdocumenttypes",
            UploadDocument: "/api/projectquotedocument/uploaddocument",
        },
        ProjectQuoteNote: {
            DeleteNoteById: "/api/projectquotenote/deletenotebyid",
            GetNoteAndRelatedById: "/api/projectquotenote/getnotewithrelatedbyid",
            GetNotesByProjectId: "/api/projectquotenote/getnotesbyprojectid",
            NoteDocumentDeleteById: "/api/projectquotenote/notedocumentdeletebyid",
            NoteDocumentUpload: "/api/projectquotenote/notedocumentupload",
            UpsertNote: "/api/projectquotenote/upsertnote",
        },
        Property: {
            AddPropertyOccupier: "/api/property/addpropertyoccupier",
            DeleteMasterProjectById: "/api/property/deletemasterprojectbyid",
            DeleteMultiSiteProjectById: "/api/property/deletemultisiteprojectbyid",
            DeleteProjectQuoteById: "/api/property/deleteprojectquotebyid",

            DownloadCSV: "api/property/downloadcsv",
            GetOccupierByIdForModal: "/api/property/getOccupierByIdForModal",
            GetOccupierListAndRelated: "/api/property/getoccupierlistAndRelated",
            GetPropertyByIdWithRelated: "/api/property/GetPropertyByIdWithRelated/:id",
            GetPropertyByIdWithRelatedForInfoCallout: "/api/property/getpropertyforcallout/:id",
            GetPropertyListAndRelated: "/api/property/getpropertylistAndRelated",
            Upsert: "/api/property/upsert",
        },
        Reports: {
            CustomerCoatingCSV: "api/reports/downloadcustomercoatingcsv",
            CustomerPlygeneCSV: "api/reports/downloadscustomerplygenecsv",
            DownloadSageNotFoundCSV: "api/reports/downloadsagenotfoundcsv",
            GetSageNotFoundCount: "api/reports/getsagenotfoundcount",
            SageNotFoundReset: "api/reports/sagenotsetreset",
            ProjectCustomerCSV: "api/reports/downloadsProjectCustomerCSV",
            ProjectMasterAndProjectQuote: "api/reports/downloadsProjectMasterAndQuoteCSV",
            ProjectDocumentCategoryCSV: "api/reports/downloadsProjectDocumentCategoryCSV",
            ProjectSageContractorCSV: "api/reports/downloadsProjectSageContractorCSV",
            ProjectCommunicationCSV: "api/reports/downloadsProjectCommunicationCSV",
            ProjectComplaintCSV: "api/reports/downloadsProjectComplaintCSV",
            CustomerContactCSV: "api/reports/downloadsCustomerContactCSV",
        },
        Roles: {
            GetAll: "/api/roles/getall",
        },
        Tsm: {
            GetTSMListAndRelated: "/api/tsm/gettsmlistAndRelated",
            upsertNote: "/api/tsmnote/upsert",
            GetNotesListById: "/api/tsmnote/gettsmnotelistbyid/:id",
            DeleteNoteById: "/api/tsmnote/deletebyid",
            ToggleShowOnTsmTab: "/api/tsm/toggleVisible",
            ToggleSiteVisited: "/api/tsm/toggleSiteVisited",
            GetDocumentsListById: "/api/tsmdocument/gettsmdocumentlistbyid/:id",
            UploadTsmDocument: "/api/tsmdocument/uploaddocument",
            DeleteTsmDocumentById: "/api/tsmdocument/deletedocumentbyid",
            UpsertRequestSiteVisit: "/api/tsm/upsertsitevisit",
            GetTsmsForAdminDropdown: "/api/tsm/gettsmsforadmindropdown",
        },
    };
}
export const AppUrls = new GlobalAppUrls();
