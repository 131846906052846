import { IObservableArray, action, computed, makeObservable, observable, runInAction } from "mobx";
import { CoreStoreInstance, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ContactsListModel, ContactsListModelValidator } from "./ContactsListModel";
import { container } from "tsyringe";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { ContactsListItemModel } from "./ContactsListItemModel";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { ContactListAndRelatedResponse, GetContactListAndRelatedEndpoint } from "./EndPoints/GetContactListAndRelatedEndpoint";
import { DownloadContactsCSVEndpoint } from "./EndPoints/DownloadContactCSVEndpoint";
import { ContactDocumentItemViewModel } from "../Customers/NewCustomer/CustomerContacts/ContactDocumentItemViewModel";
import { UploadContactDocumentEndpoint } from "../Customers/Endpoints/UploadContactDocument";
import { File64, getFileTo64 } from "Utils/File";
import { GetContactByIdEndpoint } from "../Customers/Endpoints/GetContactById";
import { UpdateContactEndpoint } from "./EndPoints/UpdateContactEndpoint";
import { DeleteContactByIdAndReturnListEndpoint } from "./EndPoints/DeleteContactByIdAndReturnList";
import { CustomerTypeDropdownItem } from "Application/Models/Domain/Customer/CustomerTypeDropdownItem";
import { SubmitType } from "Application/Models/Domain/Forms";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { IDocumentType } from "Models/Documents/DocumentType";
import { ContactStatusDropdownItem } from "Models/Contacts/ContactStatusDropdownItem";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { ModalType } from "Application/Models/Domain/AddEdditModalType";
import { GetcustomerDropdownEndpoint } from "./EndPoints/GetCustomersForDropdownEndpoint";
import { GetCustomerTypesForDropdownEndpoint } from "./EndPoints/GetCustomerTypesForDropdownEndpoint";
import { DeleteContactDocumentByIdEndpoint } from "../Customers/Endpoints/DeleteContactDocumentById";
import { DocumentDownloaderEndpoint, DocumentDownloaderRequest } from "Endpoint/DocumentDownloader";

export class ContactsListViewModel extends ViewModelBase<ContactsListModel> {
    // Singleton Patten - Uses instance and a private static variable to ensure only one instance is created and a private Constructor
    private static _instance: ContactsListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;

    public initialLoad: boolean = true;

    // Filtering top section

    public keywordSearch: string = "";
    public filterByType: number[] = [];
    public filterBycustomer: Guid | null = null;
    public filterByPlygeneTrained: string[] = [];

    public filterByCoatingsTrained: string[] = [];

    // Search String
    public searchString: string = "";

    // Table ordering

    public orderBy: SortOrderDirection = SortOrderDirection.ASC;
    public columnName: string = "name";

    // Pagination

    public contactCount: number = 0;
    public pageSize: number = DefaultPageSize;
    public pageCount: number = 0;
    public pageNumber: number = 1;

    // Related

    public customerTypes: IObservableArray<CustomerTypeDropdownItem> = observable([]);
    public customers: IObservableArray<CustomerTypeDropdownItem> = observable([]);

    // Edit Contact Modal

    public showAddContactModal: boolean = false;
    public documentTypes: IObservableArray<IDocumentType> = observable([]);
    public statusTypes: IObservableArray<ContactStatusDropdownItem> = observable([]);
    public showDocumentsSection: boolean = false;
    public documentsToUpload: ContactDocumentItemViewModel[] = [];
    public validateDocumentsToUpload: boolean = false;
    public uploadedDocuments: IObservableArray<IDocumentUploaded> = observable([]);
    public contactStatusTypes: IObservableArray<ContactStatusDropdownItem> = observable([]);

    // Add contact modal
    public debounceTimer: NodeJS.Timeout | null = null;
    public contactCustomerTypes: KeyValuePair[] = [];

    // View Contact Modal
    public showViewContactModal: boolean = false;
    public contactIdToView: string = "";

    // Deleting Contact

    public areYouSureModelOpen: boolean = false;
    public contactToDeleteId: Guid = "";

    private constructor() {
        super(new ContactsListModel());
        this.setValidator(new ContactsListModelValidator());
        makeObservable(this, {
            contactIdToView: observable,
            contactCustomerTypes: observable,
            keywordSearch: observable,
            filterByType: observable,
            filterBycustomer: observable,
            filterByPlygeneTrained: observable,
            filterByCoatingsTrained: observable,
            searchString: observable,
            contactCount: observable,
            showAddContactModal: observable,
            showDocumentsSection: observable,
            showViewContactModal: observable,
            isProcessing: observable,
            areYouSureModelOpen: observable,
            contactToDeleteId: observable,
            documentsToUpload: observable,

            addDocumentFile: action,
            addFiles: action,
            clear: action,
            closeAreYouSureModal: action,
            // ICommand closeEditContactModal: action,
            confirmDeleteContact: action,
            deleteContactCheck: action,
            deleteContactByIdAsync: action,
            downloadCSVAsync: action,
            getContactById: action,
            loadContacts: action,
            loadContactsAsync: action,
            loadCustomerTypesDropdown: action,
            openEditContactModal: action,
            openViewcontactModal: action,
            openAddContactModal: action,

            updateContact: action,
            // ICommand updateKeywordSearch: action,

            getCanExecute: computed,
            getContacts: computed,
            getCustomersForDropdown: computed,
            getStatusTypesForDropdown: computed,
            getCustomers: action,
            clearCustomersDropdownList: action,
            getCustomerTypesForAddModal: action,

            getCustomerTypesForDropdown: computed,
            getCustomerTypesFilter: computed,
            updateCustomerTypesFiltering: action,
            customerTypesSelectAll: action,
            customerTypesSelectNone: action,

            getPlygeneTrainedFilter: computed,
            updatePlygeneTrainedFiltering: action,
            plygeneTrainedSelectNone: action,
            plygeneTrainedSelectAll: action,

            getCoatingsTrainedFilter: computed,
            updateCoatingsTrainedFiltering: action,
            coatingsTrainedSelectAll: action,
            coatingsTrainedSelectNone: action,

            getDocTypesForDropdown: computed,
        });
    }

    public clear = (): void => {
        this.keywordSearch = "";
        this.filterByType = [];
        this.documentTypes.clear();
        this.filterBycustomer = null;
        this.filterByPlygeneTrained = [];
        this.filterByCoatingsTrained = [];
        this.contactCount = 0;
        this.pageNumber = 1;
        this.pageSize = 10;
        this.pageCount = 0;

        this.contactToDeleteId = "";
        this.model.clear();
    };

    public reset = () => {
        this.searchString = "";
        this.customerTypesSelectAll();
        this.plygeneTrainedSelectAll();
        this.coatingsTrainedSelectAll();
    };

    public getCustomerIdForRowId(rowId: string | number): Guid {
        let retVal: Guid = "";
        let contact: ContactsListItemModel | undefined = this.model.contacts.find((a) => a.id === rowId);

        if (contact !== undefined) {
            retVal = contact.customerId;
        }

        return retVal;
    }

    public get getCanExecute(): boolean {
        return !this.isProcessing;
    }

    public get IsBusy(): boolean {
        return this.apiClient.IsBusy;
    }

    // Customer types

    public get getCustomerTypesForDropdown() {
        let newArray = this.customerTypes.map((type) => {
            return { key: type.id, text: type.name };
        });
        return newArray;
    }

    public get getCustomerTypesFilter() {
        return this.filterByType.slice();
    }

    public updateCustomerTypesFiltering = (values: number[]) => {
        this.filterByType = values;
    };

    public customerTypesSelectAll = () => {
        this.filterByType = this.customerTypes.map((item) => {
            return item.id;
        });
    };

    public customerTypesSelectNone = () => {
        this.filterByType = [];
    };

    //Plygene trained

    public get getPlygeneTrainedFilter(): string[] {
        return this.filterByPlygeneTrained.slice();
    }

    public updatePlygeneTrainedFiltering = (values: string[]) => {
        this.filterByPlygeneTrained = values;
    };

    public plygeneTrainedSelectAll = () => {
        this.filterByPlygeneTrained = this.getBooleanOptionsForDropdown.map((item) => {
            return item.key;
        });
    };

    public plygeneTrainedSelectNone = () => {
        this.filterByPlygeneTrained = [];
    };

    //Coatings trained

    public get getCoatingsTrainedFilter(): string | string[] {
        return this.filterByCoatingsTrained.slice();
    }

    public updateCoatingsTrainedFiltering = (values: string[]) => {
        this.filterByCoatingsTrained = values;
    };

    public coatingsTrainedSelectAll = () => {
        this.filterByCoatingsTrained = this.getBooleanOptionsForDropdown.map((item) => {
            return item.key;
        });
    };

    public coatingsTrainedSelectNone = () => {
        this.filterByCoatingsTrained = [];
    };

    public loadContactsAsync = async (): Promise<ContactListAndRelatedResponse> => {
        const endpoint = new GetContactListAndRelatedEndpoint(this);
        let retVal: ContactListAndRelatedResponse = new ContactListAndRelatedResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint, this);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response: ContactListAndRelatedResponse = this.apiClient.Response();
                    // this.customers.replace(response.customerList);
                    this.contactCount = response.contactCount;
                    this.pageCount = Math.ceil(this.contactCount / this.pageSize);

                    if (this.initialLoad === true) {
                        this.customers.clear();
                        this.customerTypes.clear();
                        this.contactStatusTypes.clear();

                        this.customerTypes.replace(
                            response.customerTypesList.map((item) => {
                                return { id: item.id, name: item.name };
                            }),
                        );

                        // Reset the filters to ALL
                        this.customerTypesSelectAll();
                        this.plygeneTrainedSelectAll();
                        this.coatingsTrainedSelectAll();

                        this.initialLoad = false;
                    }
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Contacts");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the contact list.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public loadContacts(Contacts: ContactsListItemModel[]) {
        this.model.contacts.clear();
        this.model.contacts.replace(Contacts);
    }

    public loadCustomerTypesDropdown(CustomerTypes: CustomerTypeDropdownItem[]) {
        this.customerTypes.clear();
        this.customerTypes.replace(CustomerTypes);
    }

    public get getCustomersForDropdown() {
        let newArray = this.customers.map((type) => {
            return { key: type.id, text: type.name };
        });
        return newArray;
    }

    public get getBooleanOptionsForDropdown() {
        let retVal: KeyValuePair[] = [
            // { key: null, text: "All" },
            { key: true, text: "Yes" },
            { key: false, text: "No" },
        ];

        return retVal;
    }

    public setFilterByType: ICommand = new RelayCommand(
        (value: KeyValuePair) => {
            this.filterByType = value.key;
            this.pageNumber = 1;
            this.loadContactsAsync();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public setFilterByCustomer: ICommand = new RelayCommand(
        (value: KeyValuePair) => {
            this.filterBycustomer = value.key;
            this.pageNumber = 1;
            this.loadContactsAsync();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public setFilterByPlygeneTrained: ICommand = new RelayCommand(
        (value: KeyValuePair) => {
            this.filterByPlygeneTrained = value.key;
            this.pageNumber = 1;
            this.loadContactsAsync();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public setFilterByCoatingsTrained: ICommand = new RelayCommand(
        (value: KeyValuePair) => {
            this.filterByCoatingsTrained = value.key;
            this.pageNumber = 1;
            this.loadContactsAsync();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public get getContacts(): ContactsListItemModel[] {
        return this.model.contacts.slice();
    }

    public updateKeywordSearch: ICommand = new RelayCommand(
        (keyword: string) => {
            this.searchString = keyword;
            this.pageNumber = 1;
            this.loadContactsAsync();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public async downloadCSVAsync(): Promise<void> {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            const endpoint = new DownloadContactsCSVEndpoint(this);
            await this.apiClient.sendAsync(endpoint);
            this.isProcessing = true;

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Contacts");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the CSV.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public openEditContactModal = (e: any, id: string) => {
        this.model.addEditContactModalType = ModalType.Edit;
        this.getContactById(id);
        this.showDocumentsSection = true;
        this.showAddContactModal = true;
    };

    public openAddContactModal = () => {
        this.model.addEditContactModalType = ModalType.Add;
        this.model.clearContactModal();
        this.showDocumentsSection = false;
        this.showAddContactModal = true;
    };

    public setCustomerType = (type: number) => {
        this.setValue("customerType", type);
    };

    public isCustomerTypeSelected = () => {
        if (this.model.customerType > 0) {
            return true;
        } else {
            return false;
        }
    };

    public selectCustomer: ICommand = new RelayCommand(
        (customer: KeyValuePair) => {
            this.setValue("customerId", customer.key);
        },
        () => {
            return this.getCanExecute;
        },
    );

    public getCustomers = (searchString: string) => {
        this.setValue("searchString", searchString);
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }

        this.debounceTimer = setTimeout(() => {
            if (this.model.searchString.length > 0) {
                this.getCustomerBySearchString();
            } else {
                this.clearCustomersDropdownList();
            }
        }, 600);
    };

    public clearCustomersDropdownList = () => {
        this.model.customers = [];
    };

    public async getCustomerBySearchString(): Promise<void> {
        // this.spinnerText = "Saving...";

        const endpoint = new GetcustomerDropdownEndpoint();
        await this.apiClient.sendAsync(endpoint, this.model);

        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                let response = this.apiClient.Response();
                this.model.customers = response.customers;

                // this.spinnerText = "Loading...";
            });
        } // else show error message
        else {
            runInAction(() => {});
            this.errorStore.setHeaderText("Contact");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to search customers.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }

    public closeEditContactModal: ICommand = new RelayCommand(() => {
        this.documentsToUpload = [];
        this.showAddContactModal = false;
        CoreStoreInstance.HideInfoBar();
        if (!this.isModelValid()) {
            CoreStoreInstance.HideInfoBar();
            this.setError("customerType", "");
            this.setError("firstName", "");
            this.setError("lastName", "");
            this.setError("email", "");
            this.setError("phone", "");
            this.setError("phone2", "");
            this.setError("datePlygeneTrained", "");
            this.setError("dateCoatingsTrained", "");
            this.setError("dateCoatingsCPD", "");
            this.setError("datePlygeneCPD", "");
        }
    });

    public deleteContactCheck = (e: any, id: string): void => {
        this.contactToDeleteId = id;
        this.areYouSureModelOpen = true;
    };

    public closeAreYouSureModal = () => {
        this.areYouSureModelOpen = false;
    };

    public confirmDeleteContact = () => {
        this.deleteContactByIdAsync();
        this.areYouSureModelOpen = false;
    };

    // Attachments * * * * * * * * * * * * * * * * * * * * * //

    // Files - Add files to list in modal, ready for uploading
    public addFiles = (acceptedFiles: File[]) => {
        acceptedFiles.map((file: File) => {
            const allowedFileTypes = [".doc", ".docx", ".pdf", ".xls", ".xlsx", ".csv", ".jpg", ".png"];
            const fileType = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
            if (allowedFileTypes.includes(fileType)) {
                const viewModel = new ContactDocumentItemViewModel();
                viewModel.setValue("filename", file.name);
                viewModel.setValue("documentCategoryId", 0);
                viewModel.setValue("file", file);

                this.documentsToUpload.push(viewModel);
                this.validateDocumentsToUpload = false;
            } else {
                this.errorStore.setHeaderText("Invalid Files");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne(`Invalid file type: ${fileType}. Only .doc, .pdf, .xls, .csv, .jpg, .png are allowed.`);
                this.errorStore.setErrorModalOpen(true);
            }
        });
    };

    // Files - remove file from list in modal
    public removeFileFromModalList: ICommand = new RelayCommand(
        (index: number) => {
            const copy = [...this.documentsToUpload];
            copy.splice(index, 1);
            this.documentsToUpload = copy;
        },
        () => {
            return this.getCanExecute;
        },
    );

    // Files - validate, then submit to server for blob storage and database
    public submitDocuments: ICommand = new RelayCommand(
        () => {
            let areViewModelsValid: boolean = true;

            for (let i = 0; i < this.documentsToUpload.length; i++) {
                if (this.documentsToUpload[i].isModelValid() === false) {
                    areViewModelsValid = false;
                }
            }

            if (areViewModelsValid === true) {
                this.addDocumentFile();
            }
        },
        () => {
            return this.getCanExecute;
        },
    );

    public addDocumentFile = async () => {
        this.validateDocumentsToUpload = true;
        // TODO check validation before proceeding

        this.isProcessing = true;
        this.apiClient.setAPITimeout(240000);

        if (this.documentsToUpload.length > 0) {
            for (let i = 0; i < this.documentsToUpload.length; i++) {
                // this.spinnerText = "Uploading document " + (i + 1) + " of " + this.documentsToUpload.length;
                let uploadedFile: File64 = await getFileTo64(this.documentsToUpload[i].getValue("file"));
                this.documentsToUpload[i].setValue("fileBase64", uploadedFile.base64StringFile);
                this.documentsToUpload[i].setValue("fileType", uploadedFile.fileType);
                this.documentsToUpload[i].setValue("contactId", this.getValue("id"));

                let endpoint: UploadContactDocumentEndpoint = new UploadContactDocumentEndpoint(this.documentsToUpload[i].model);

                let _ = await this.apiClient.sendAsync(endpoint);
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        this.isProcessing = false;
                        let response: IDocumentUploaded[] = this.apiClient.Response();
                        this.uploadedDocuments.replace(response);
                        // this.showAddDocumentModal = false;
                        this.documentsToUpload = [];
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Documents");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to upload the document.  Please correct the file and try again");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }
    };

    public downloadDocumentCommand = (e: any, id: string): void => {
        let foundDoc: IDocumentUploaded | undefined = this.uploadedDocuments.find((a) => a.id === id);
        if (foundDoc !== undefined) {
            let documentToDownload = new DocumentDownloaderRequest();
            documentToDownload.id = foundDoc.id;
            documentToDownload.fileName = foundDoc.fileName;
            documentToDownload.blobName = foundDoc.blobName;
            documentToDownload.fileType = foundDoc.fileType;
            documentToDownload.docType = foundDoc.docType;
            this.downloadDocumentAsync(documentToDownload);
        }
    };

    public async downloadDocumentAsync(documentToDownload: DocumentDownloaderRequest): Promise<void> {
        if (documentToDownload !== undefined) {
            const endpoint = new DocumentDownloaderEndpoint();

            await this.apiClient.sendAsync(endpoint, documentToDownload);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response = this.apiClient.Response();
                });
            } else {
                runInAction(() => {});
                this.errorStore.setHeaderText("Documents");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the file.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public validateAndSubmitContact: ICommand = new RelayCommand(
        (submitType: SubmitType) => {
            this.updateContact(submitType);
        },
        () => {
            return this.getCanExecute;
        },
    );

    public async updateContact(submitType: SubmitType): Promise<void> {
        this.isProcessing = true;
        if (this.isModelValid()) {
            const endpoint = new UpdateContactEndpoint(this);
            await this.apiClient.sendAsync(endpoint);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response = this.apiClient.Response();
                    this.isProcessing = false;
                    CoreStoreInstance.HideInfoBar();
                    if (submitType === SubmitType.SaveAndExit) {
                        this.showAddContactModal = false;
                        this.uploadedDocuments.clear();
                        this.documentsToUpload = [];
                    } else if (submitType === SubmitType.SaveAndContinue) {
                        this.setValue("id", response.updatedId);
                        this.showDocumentsSection = true;
                    }
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Contact");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to save contact.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        } else {
            this.isProcessing = false;
        }
    }

    public get getStatusTypesForDropdown() {
        let types: KeyValuePair[] = [];

        this.contactStatusTypes.map((type) => {
            types.push({ key: type.id, text: type.statusType });
        });
        return types;
    }

    public async getContactById(id: Guid): Promise<void> {
        // this.spinnerText = "Saving...";
        this.isProcessing = true;
        if (id !== null) {
            const endpoint = new GetContactByIdEndpoint(id);
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response = this.apiClient.Response();
                    this.model.fromResponse(response);
                    this.uploadedDocuments.replace(response.documents);

                    this.isProcessing = false;
                    // this.spinnerText = "Loading...";
                });
            } // else show error message
            else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Contact");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get contact.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public async deleteContactByIdAsync(): Promise<void> {
        const endpoint = new DeleteContactByIdAndReturnListEndpoint(this);
        await this.apiClient.sendAsync(endpoint);
        this.isProcessing = true;

        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                this.pageCount = Math.ceil(this.contactCount / this.pageSize);
                this.isProcessing = false;
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Contact");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to delete contact.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }

    public clearFiltering: ICommand = new RelayCommand(
        () => {
            this.filterByType = [];
            this.filterByPlygeneTrained = [];
            this.filterByCoatingsTrained = [];
            this.initialLoad = true;
            this.loadContactsAsync();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public changePageNumber = (pageNumber: number) => {
        this.pageNumber = pageNumber;
        this.loadContactsAsync();
    };

    // View Contact Modal

    public openViewcontactModal = (id: string) => {
        this.contactIdToView = id;
        this.showViewContactModal = true;
    };

    public closeViewContactModal: ICommand = new RelayCommand(() => {
        this.showViewContactModal = false;
    });

    // For add contact modal...

    public async getCustomerTypesForAddModal(): Promise<void> {
        // this.spinnerText = "Saving...";

        const endpoint = new GetCustomerTypesForDropdownEndpoint();
        await this.apiClient.sendAsync(endpoint);

        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                let response = this.apiClient.Response();
                this.documentTypes.clear();
                this.documentTypes.replace(response.documentType);
                this.contactCustomerTypes = response.customerTypes;

                // this.spinnerText = "Loading...";
            });
        } // else show error message
        else {
            runInAction(() => {});
            this.errorStore.setHeaderText("Contact");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to load customer types.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }

    public get getDocTypesForDropdown() {
        let types: KeyValuePair[] = [];

        this.documentTypes.map((type) => {
            types.push({ key: type.id, text: type.categoryName });
        });

        return types;
    }

    public get getCustomerTypesForModalDropdown(): KeyValuePair[] {
        return this.contactCustomerTypes.slice();
    }

    public deleteDocumentByIdCommand: ICommand = new RelayCommand(
        (Id: Guid) => {
            this.deleteDocument(Id, this.getValue("id"));
        },
        () => {
            return this.getCanExecute;
        },
    );

    public async deleteDocument(Id: Guid, ContactId: Guid): Promise<void> {
        this.isProcessing = true;
        const endpoint = new DeleteContactDocumentByIdEndpoint(Id, ContactId);
        await this.apiClient.sendAsync(endpoint);

        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                const response: IDocumentUploaded[] = this.apiClient.Response();

                this.uploadedDocuments.replace(response);

                this.isProcessing = false;
            });
        } // else show error message
        else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Documents");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to delete document.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }
}
